import React, {useEffect, useState} from "react";
import {graphql, navigate} from "gatsby";
import Breadcrumbs from "./Breadcrumbs";
import SelectedVideo from "./SelectedVideo";
import Playlist from "./Playlist";

const styles = require("./VideoCourse.module.scss");

interface Props {}

function calculateContentHeight() {
	if (typeof window === "undefined") {
		return 0;
	}

	return window.innerHeight - 65;
}

function calculateInitialSelectedVideo(videos, selectedVideoId: string|undefined) {
	if (!selectedVideoId) {
		return videos[0];
	}

	const video = videos.find((video) => video.id === selectedVideoId);

	if (!video) {
		return videos[0];
	}

	return video;
}

const Course: React.FunctionComponent<Props> = ({data, pageContext}) => {
	const videos = data.allSanityVideoSeries.nodes.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));

	const [selectedVideo, setSelectedVideo] = useState(calculateInitialSelectedVideo(videos, pageContext.videoId));
	const [isPlaying, setIsPlaying] = useState(true);
	const [height, setHeight] = useState(calculateContentHeight());

	useEffect(() => {
		setHeight(calculateContentHeight());
	}, []);

	if (!selectedVideo) {
		return null;
	}

	function autoplayNextVideo() {
		const selectedVideoIndex = videos.findIndex((video) => video.id === selectedVideo.id);

		if (videos[selectedVideoIndex + 1] === undefined) {
			// We're on the last video
			return;
		}

		const video = videos[selectedVideoIndex + 1];
		const name = video.name.replaceAll(" ", "-").toLowerCase();

		navigate(`/getting-started/part-${video.chapter.chapter}/${name}`)
	}

	return (
		<div className={styles.Course} style={{minHeight:`${height}px`}}>
			<div className={styles.PageTitle}>
				<h1>Get Started with Prestavi</h1>
			</div>
			<Breadcrumbs currentChapter={pageContext.chapter} />

			<div className={styles.Content}>

				<SelectedVideo name={selectedVideo.name} videoUrl={selectedVideo.videoUrl} isPlaying={isPlaying} onVideoEnded={autoplayNextVideo} />

				<Playlist
					chapterNumber={pageContext.chapter}
					chapterName={pageContext.chapterName}
					selectedVideoId={selectedVideo.id}
					videos={videos}
					onSelect={(video) => {
						const name = video.name.replaceAll(" ", "-").toLowerCase();
						navigate(`/getting-started/part-${video.chapter.chapter}/${name}`)
					}}
					onWatchExternally={() => setIsPlaying(false)}
				/>

			</div>
		</div>
	);
};

export const query = graphql`
	query GetVideoSeriesItems($chapter: Float) {
		allSanityVideoSeries(filter: {chapter: {chapter: {eq: $chapter}}}) {
		nodes {
		  id
		  chapter {
			chapter
		  }
		  name
		  order
		  videoUrl
		  youtubeUrl
		  duration
		}
	  }
	}
`

export default Course;
