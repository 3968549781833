import React from "react";
import ReactPlayer from "react-player";

const styles = require("./VideoCourse.module.scss");

interface Props {
    name: string;
    videoUrl: string;
    isPlaying: boolean;
    onVideoEnded: () => void;
}

const SelectedVideo: React.FunctionComponent<Props> = ({name, videoUrl, isPlaying, onVideoEnded}) => {

    return (
        <div className={styles.SelectedVideo}>
            <span>You are watching</span>
            <h3>{name}</h3>

            <div className={styles.Video}>
                <ReactPlayer
                    width="100%"
                    height="100%"
                    playing={isPlaying}
                    controls={true}
                    url={videoUrl}
                    onEnded={onVideoEnded}
                    config={{
                        file: {
                            attributes: {controlsList: 'nodownload'}
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default SelectedVideo;
