// extracted by mini-css-extract-plugin
export var Breadcrumbs = "VideoCourse-module--Breadcrumbs--BL1rz";
export var Chapter = "VideoCourse-module--Chapter--c46ER";
export var Chapters = "VideoCourse-module--Chapters--FdQsm";
export var ComingSoon = "VideoCourse-module--ComingSoon---YBOl";
export var Content = "VideoCourse-module--Content--OYzMi";
export var Course = "VideoCourse-module--Course--Za5jD";
export var Details = "VideoCourse-module--Details--EO7MC";
export var Order = "VideoCourse-module--Order--sY2Oa";
export var Overview = "VideoCourse-module--Overview--L6L5d";
export var PageTitle = "VideoCourse-module--PageTitle--zD-cv";
export var Part = "VideoCourse-module--Part--jOVj9";
export var Playlist = "VideoCourse-module--Playlist--vSZv1";
export var PlaylistOption = "VideoCourse-module--PlaylistOption--JrNvq";
export var Selected = "VideoCourse-module--Selected--Ezze8";
export var SelectedVideo = "VideoCourse-module--SelectedVideo--Jvil4";
export var Video = "VideoCourse-module--Video--H7oXQ";
export var Youtube = "VideoCourse-module--Youtube--hnsaA";