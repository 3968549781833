import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";

const styles = require("./VideoCourse.module.scss");

interface Props {
	currentChapter: number;
}

function calculatePrev(currentChapter, chapters) {
	if (currentChapter === 1) {
		return (
			<Link to='/getting-started'>Back to Overview</Link>
		)
	}

	const chapter = chapters[currentChapter - 1];

	return (
		<Link to={`/getting-started/part-${currentChapter - 1}`}>Part {chapter.chapter}: {chapter.name}</Link>
	)
}

function calculateNext(currentChapter, chapters) {
	if (currentChapter === chapters.length) {
		return null;
	}

	const chapter = chapters[currentChapter];

	return (
		<Link to={`/getting-started/part-${currentChapter + 1}`}>Part {chapter.chapter}: {chapter.name}</Link>
	)
}

const Breadcrumbs: React.FunctionComponent<Props> = ({currentChapter}) => {
	const data = useStaticQuery(graphql`
	  {
		allSanityVideoSeriesChapters {
			nodes {
			  id
			  name
			  chapter
			}
		  }
	  }
	`)
	const chapters = data.allSanityVideoSeriesChapters.nodes.sort((a,b) => (a.chapter > b.chapter) ? 1 : ((b.chapter > a.chapter) ? -1 : 0));

	return (
		<div className={styles.Breadcrumbs}>
		    <div>
				{calculatePrev(currentChapter, chapters)}
			</div>
			<div>
				{calculateNext(currentChapter, chapters)}
			</div>
		</div>
	);
};


export default Breadcrumbs;
