import React from "react";
import classNames from "classnames";
import YoutubeLogo from '../../../../static/assets/youtube-logo.jpeg'

const styles = require("./VideoCourse.module.scss");

interface Video {
    id: string;
    videoUrl: string;
    youtubeUrl: string;
    name: string;
    order: number;
    duration: number;
    chapter: {
        chapter: number,
    }
}

interface Props {
    chapterNumber: number;
    chapterName: string;
    selectedVideoId: string;
    videos: Array<Video>;
    onSelect:(video: Video) => void;
    onWatchExternally: () => void;
}

const Playlist: React.FunctionComponent<Props> = ({chapterNumber, chapterName, selectedVideoId, videos, onSelect, onWatchExternally}) => {

    function handleClick(video: Video) {
        if (!video.videoUrl) {
            return;
        }

        onSelect(video)
    }

    const selectedVideo = videos.find((video) => video.id === selectedVideoId);

    const playlistOptions = videos
        .map((video) => {
        return (
            <div key={video.id} className={classNames([styles.PlaylistOption, selectedVideoId === video.id && styles.Selected, !video.videoUrl && styles.ComingSoon])} onClick={() => handleClick(video)}>
                <div className={styles.Order}>
                    {video.order}
                </div>
                <div className={styles.Details}>
                    <p>{video.name}</p>
                    {video.videoUrl ? <span>{video.duration} {video.duration === 1 ? "minute": "minutes"}</span> : <span>Coming Soon</span>}
                </div>
            </div>
        )
    })
	return (
		<div className={styles.Playlist}>
            <div className={styles.Part}>
                <h5>Part {chapterNumber}</h5>
                <p>{chapterName}</p>
            </div>
            {playlistOptions}

            {selectedVideo?.youtubeUrl && (
                <div className={styles.Youtube}>

                    <a href={selectedVideo.youtubeUrl} target="_blank" onClick={onWatchExternally}>
                        <img src={YoutubeLogo} alt="Youtube Logo"/>
                        Watch on YouTube
                    </a>
                </div>
            )}
		</div>
	);
};

export default Playlist;
